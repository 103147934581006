import * as React from "react"
// import ReactHtmlParser from "react-html-parser"
// import { Link } from "gatsby"

const IndexPage = ({img, header1, header2, imgclass}) => (

	img &&
  <div className={imgclass} style={{ backgroundImage: `url("${img}")` }}>
    
	<h1>{header1}</h1>

	<h2>{header2}</h2>
	
  </div>
	
)

export default IndexPage
