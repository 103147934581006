import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SeoBasic from "../components/seo"
import ReactHtmlParser from "react-html-parser"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Link } from "gatsby"
import Banner from "../components/bannerImageText"
import getImage from "../functions/getImage"
import CCMap from "../components/map"

export const query = graphql`
  query {
    wpPage(slug: {eq: "about"}) {
    title
    content
    about {
      header1
      copy1
      image1 {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
      headerimage {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
    }
    }
    allWpMapLocation {
      edges {
        node {
          id
          title
          mapLocations {
            latitude
            longitude
          }
        }
      }
    }
    seoPage:wpPage(slug: {eq: "about"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
   
  }
`


const IndexPage = ({
  data: {
    wpPage: { title, content, about }, seoPage, allWpMapLocation
  },
}) => {

  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title={title} />
    }
    {about.headerimage && about.headerimage.mediaDetails &&
    <Banner img={getImage(about.headerimage.mediaDetails.sizes)} header1={about.header1} imgclass="about header" />
    }

    <div style={{backgroundColor:"#2F2F2F"}}>
      
      <div className="simpleText about">
        
        {ReactHtmlParser(content)}

      </div>

      <div className="about imageText" style={{ backgroundImage: `url("${getImage(about.image1.mediaDetails.sizes)}")` }}>
 
      </div>

      <div className="about imageTextCopy">
          
          {ReactHtmlParser(about.copy1)}

      </div>

      <div className="map">
        <h2>Project Locations</h2>
        
        <CCMap data={allWpMapLocation} />

      </div>

        
      <div className="about button">

        <Link activeClassName="active" to="/meet-the-team/"><button>MEET THE TEAM</button></Link>

      </div>

    </div>

  </Layout>
)
}

export default IndexPage
