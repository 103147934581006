import React, { useState, useEffect } from "react"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import yellowMarker from '../images/marker-yellow.png'

const MapPage = ({data}) => {

  const [loadMapSection, setLoadMapSection] = useState(false)
  
  useEffect(() => {

      if (typeof window !== "undefined") {
        
        setLoadMapSection(true)

      }
  }, [])


  /* https://stackblitz.com/edit/react-xqvbdp?file=Map.js */
  function getMarkerIcon(index) {
    
    return createIcon(yellowMarker);
  }

  function createIcon(url) {
    return new L.Icon({
      iconUrl: url,
    });
  }

  const GetMarkers = ({data}) => {

      let position = [0,0]

      const locations = data.edges.map((item, i) => (
          
        position = [item.node.mapLocations.latitude, item.node.mapLocations.longitude],

        <Marker key={i} position={position} icon={getMarkerIcon(0)}>
          {/*<Popup>
            {item.node.title}
          </Popup>*/}
        </Marker>
          
      ))
      
      return locations

  }

  return (
      
       loadMapSection &&
	     <MapContainer center={[31.628674, -7.992047]} zoom={2} minZoom={2} maxZoom={8}>
          
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
             url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
          />

          <GetMarkers data={data} />
        
        </MapContainer>

    )

}

export default MapPage
